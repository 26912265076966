import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, fromEvent, map, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutControlService {
  private widthSubject:BehaviorSubject<number>;
  private layoutChangeSubject = new Subject<any>;
  private resizeObservable$: Observable<Event>;
  private widthChange$: Observable<number>;
  private subjectRouter:BehaviorSubject<any> = new BehaviorSubject<any>(null);
  layoutChange$ = this.layoutChangeSubject.asObservable();

  constructor(private router:Router) {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event:NavigationEnd) => {
      this.subjectRouter.next(event.urlAfterRedirects)
    });

    const initialWidth = window.innerWidth;
    this.widthSubject = new BehaviorSubject<number>(initialWidth);
    this.widthChange$ = this.widthSubject.asObservable();

    this.resizeObservable$ = fromEvent(window, 'resize');
    this.initilizeObservableResize();
  }

  getObservableRouter():Observable<any>{
    return this.subjectRouter.asObservable();
  }

  initilizeObservableResize(){
    this.resizeObservable$.pipe(
      map((response: Event) => {
        const width = (response.target as Window).innerWidth;
        this.widthSubject.next(width);
        return response;
      })
    ).subscribe();
  }

  isDesktop(): Observable<boolean> {
    return this.resizeObservable$.pipe(
      map((event: Event) => {
        console.log("DIMENSAO: "+ (event.target as Window).innerWidth )
        return (event.target as Window).innerWidth > 520;
      })
    );
  }

  returnWidthScreen(): Observable<number> {
    return this.widthChange$;
  }

  applyStyles(renderer:Renderer2, element:any, obj:{ [key: string]: any }){
    var arrKeys = Object.keys(obj);
    arrKeys.forEach((prop) => {
      //this.renderer.setStyle(element,prop,obj[prop]);
      renderer.setStyle(element,prop,obj[prop]);
    })
  }

  notifyLayoutChange(change:any){
    this.layoutChangeSubject.next(change)
  }
}
